

.year{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding-top:10px;
  padding-bottom: 10px;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
}
.graph_container{
  position: absolute;
  top:50px;
  bottom: 0;
  left:0;
  right:0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}
#graph{
  width:1200px;
  height: 900px;
}