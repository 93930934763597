.title{
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  z-index: 10;
}
.img{
  display: flex;
  align-items: center;
  justify-content: center;
}
.img img{
  max-width: 100%;
  /* display: block; */
  margin:0 auto;
  /* width:900px; */
  /* height: 600px; */
}
#bubble_age img,#bubble_depth img{
  position: relative;
  top:-60px;
  z-index: -1;
}
.bubble_age .desc,.bubble_depth .desc{
  position: relative;
  top:-100px;
}
.d_scatter .desc{
  margin-top: 20px;
}


.bubble_depth{
  margin-top: -70px;
}
.d_scatter{
  margin-top: -70px;
}
.kde,.violin_plot{
  margin-top: 40px;
}
