.login_body{
  position: absolute;
  width:100%;
  height: 100%;
  background-image: linear-gradient(#0f112a,#163370);
}
.login_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 15%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  transform-origin: center center;
  background-image: url(./cube_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.login_container .login_form {
  width: 400px;
  background-color: #4a3c82a3;
  padding: 20px 30px 15px;
  border-radius: 7px !important;
  box-shadow: 0px 7px 11px #5d438e, 5px -1px 11px #5d438e, -6px 0px 11px #5d438e, 5px -1px 11px #5d438e;
}
.login_container .login-form-button{
  width:100%;
}
.login_container .login_form .ant-form-item-label > label{
  color:#fff;
}