.pies{
  display: flex;
}
.pie{
  width:25%;
  display: flex;
  height: 250px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}